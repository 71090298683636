var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "alert alert-custom alert-white alert-shadow gutter-b",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon alert-icon-top" }, [
          _c(
            "span",
            { staticClass: "svg-icon svg-icon-3x svg-icon-primary mt-4" },
            [
              _c("inline-svg", {
                attrs: { src: "media/svg/icons/Tools/Tools.svg" },
              }),
            ],
            1
          ),
        ]),
        _vm._m(0),
      ]
    ),
    _c("div", { staticClass: "card card-custom gutter-b" }, [
      _c("div", { staticClass: "card-header card-header-tabs-line" }, [
        _c(
          "ul",
          {
            ref: "builder-tab",
            staticClass: "nav nav-dark nav-bold nav-tabs nav-tabs-line",
            attrs: { role: "tablist" },
          },
          [
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link active",
                  attrs: {
                    "data-tab": "0",
                    "data-toggle": "tab",
                    href: "#",
                    role: "tab",
                  },
                  on: { click: _vm.setActiveTab },
                },
                [_vm._v(" Page ")]
              ),
            ]),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    "data-tab": "1",
                    "data-toggle": "tab",
                    href: "#",
                    role: "tab",
                  },
                  on: { click: _vm.setActiveTab },
                },
                [_vm._v(" Header ")]
              ),
            ]),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    "data-tab": "2",
                    "data-toggle": "tab",
                    href: "#",
                    role: "tab",
                  },
                  on: { click: _vm.setActiveTab },
                },
                [_vm._v(" Subheader ")]
              ),
            ]),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    "data-tab": "3",
                    "data-toggle": "tab",
                    href: "#",
                    role: "tab",
                  },
                  on: { click: _vm.setActiveTab },
                },
                [_vm._v(" Content ")]
              ),
            ]),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    "data-tab": "4",
                    "data-toggle": "tab",
                    href: "#",
                    role: "tab",
                  },
                  on: { click: _vm.setActiveTab },
                },
                [_vm._v(" Aside ")]
              ),
            ]),
            _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    "data-tab": "5",
                    "data-toggle": "tab",
                    href: "#",
                    role: "tab",
                  },
                  on: { click: _vm.setActiveTab },
                },
                [_vm._v(" Footer ")]
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "form",
        {
          staticClass: "form",
          on: {
            submit: function ($event) {
              return _vm.submit($event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "hide-tabs",
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c("b-tab", { attrs: { active: "" } }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Page Loader: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.loader.type,
                                expression: "config.loader.type",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.loader,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "false" } }, [
                              _vm._v(" Disabled "),
                            ]),
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "spinner-logo",
                                  selected: "selected",
                                },
                              },
                              [_vm._v(" Spinner & Logo ")]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select page loading indicator. "),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("b-tab", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Desktop Fixed Header: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.header.self.fixed.desktop,
                                  expression:
                                    "config.header.self.fixed.desktop",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.header.self.fixed.desktop
                                )
                                  ? _vm._i(
                                      _vm.config.header.self.fixed.desktop,
                                      "true"
                                    ) > -1
                                  : _vm.config.header.self.fixed.desktop,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.config.header.self.fixed.desktop,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.header.self.fixed,
                                          "desktop",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.header.self.fixed,
                                          "desktop",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.header.self.fixed,
                                      "desktop",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Enable fixed header for desktop mode "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Mobile Fixed Header: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.header.self.fixed.mobile,
                                  expression: "config.header.self.fixed.mobile",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.header.self.fixed.mobile
                                )
                                  ? _vm._i(
                                      _vm.config.header.self.fixed.mobile,
                                      "true"
                                    ) > -1
                                  : _vm.config.header.self.fixed.mobile,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.header.self.fixed.mobile,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.header.self.fixed,
                                          "mobile",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.header.self.fixed,
                                          "mobile",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.header.self.fixed,
                                      "mobile",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Enable fixed header for mobile mode "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Display Header Menu: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.header.menu.self.display,
                                  expression: "config.header.menu.self.display",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.header.menu.self.display
                                )
                                  ? _vm._i(
                                      _vm.config.header.menu.self.display,
                                      "true"
                                    ) > -1
                                  : _vm.config.header.menu.self.display,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.header.menu.self.display,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.header.menu.self,
                                          "display",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.header.menu.self,
                                          "display",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.header.menu.self,
                                      "display",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Display header menu "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Header Menu Layout: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.header.menu.self.layout,
                                expression: "config.header.menu.self.layout",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.header.menu.self,
                                  "layout",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "default", selected: "" } },
                              [_vm._v("Default")]
                            ),
                            _c("option", { attrs: { value: "tab" } }, [
                              _vm._v("Tab"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select header menu layout style "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Header Menu Arrows: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.config.header.menu.self["root-arrow"],
                                  expression:
                                    "config.header.menu.self['root-arrow']",
                                },
                              ],
                              attrs: { type: "checkbox", value: "true" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.header.menu.self["root-arrow"]
                                )
                                  ? _vm._i(
                                      _vm.config.header.menu.self["root-arrow"],
                                      "true"
                                    ) > -1
                                  : _vm.config.header.menu.self["root-arrow"],
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.config.header.menu.self["root-arrow"],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.header.menu.self,
                                          "root-arrow",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.header.menu.self,
                                          "root-arrow",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.header.menu.self,
                                      "root-arrow",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Enable header menu root link arrows "),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("b-tab", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Display Subheader: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.subheader.display,
                                  expression: "config.subheader.display",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.subheader.display
                                )
                                  ? _vm._i(
                                      _vm.config.subheader.display,
                                      "true"
                                    ) > -1
                                  : _vm.config.subheader.display,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.subheader.display,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.subheader,
                                          "display",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.subheader,
                                          "display",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.subheader,
                                      "display",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v("Display subheader"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Fixed Subheader: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.subheader.fixed,
                                  expression: "config.subheader.fixed",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.subheader.fixed
                                )
                                  ? _vm._i(_vm.config.subheader.fixed, "true") >
                                    -1
                                  : _vm.config.subheader.fixed,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.subheader.fixed,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.subheader,
                                          "fixed",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.subheader,
                                          "fixed",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.config.subheader, "fixed", $$c)
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Enable fixed(sticky) subheader. Requires "),
                          _c("code", [_vm._v("Solid")]),
                          _vm._v(" subheader style. "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Width:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.subheader.width,
                                expression: "config.subheader.width",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.subheader,
                                  "width",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "fluid", selected: "" } },
                              [_vm._v("Fluid")]
                            ),
                            _c("option", { attrs: { value: "fixed" } }, [
                              _vm._v("Fixed"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select layout width type. "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Subheader Style:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.subheader.style,
                                expression: "config.subheader.style",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.subheader,
                                  "style",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "transparent" } }, [
                              _vm._v("Transparent"),
                            ]),
                            _c(
                              "option",
                              { attrs: { value: "solid", selected: "" } },
                              [_vm._v("Solid")]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select subheader style "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Subheader Layout:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.subheader.layout,
                                expression: "config.subheader.layout",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.subheader,
                                  "layout",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { value: "subheader-v1", selected: "" },
                              },
                              [_vm._v(" Subheader v1 ")]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select subheader layout "),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("b-tab", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Width:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.content.width,
                                expression: "config.content.width",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.content,
                                  "width",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "fluid" } }, [
                              _vm._v("Fluid"),
                            ]),
                            _c(
                              "option",
                              { attrs: { value: "fixed", selected: "" } },
                              [_vm._v("Fixed")]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select layout width type. "),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("b-tab", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Display:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.aside.self.display,
                                  expression: "config.aside.self.display",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.aside.self.display
                                )
                                  ? _vm._i(
                                      _vm.config.aside.self.display,
                                      "true"
                                    ) > -1
                                  : _vm.config.aside.self.display,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.aside.self.display,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.aside.self,
                                          "display",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.aside.self,
                                          "display",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.aside.self,
                                      "display",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v("Display aside"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Fixed:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.aside.self.fixed,
                                  expression: "config.aside.self.fixed",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.aside.self.fixed
                                )
                                  ? _vm._i(
                                      _vm.config.aside.self.fixed,
                                      "true"
                                    ) > -1
                                  : _vm.config.aside.self.fixed,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.aside.self.fixed,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.aside.self,
                                          "fixed",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.aside.self,
                                          "fixed",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.aside.self,
                                      "fixed",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Set fixed aside layout "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Minimize:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.aside.self.minimize.toggle,
                                  expression:
                                    "config.aside.self.minimize.toggle",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                value: "true",
                                checked: "",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.aside.self.minimize.toggle
                                )
                                  ? _vm._i(
                                      _vm.config.aside.self.minimize.toggle,
                                      "true"
                                    ) > -1
                                  : _vm.config.aside.self.minimize.toggle,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.config.aside.self.minimize.toggle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.aside.self.minimize,
                                          "toggle",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.aside.self.minimize,
                                          "toggle",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.aside.self.minimize,
                                      "toggle",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Allow aside minimizing "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v(" Default Minimize: "),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.aside.self.minimize.default,
                                  expression:
                                    "config.aside.self.minimize.default",
                                },
                              ],
                              attrs: { type: "checkbox", value: "true" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.config.aside.self.minimize.default
                                )
                                  ? _vm._i(
                                      _vm.config.aside.self.minimize.default,
                                      "true"
                                    ) > -1
                                  : _vm.config.aside.self.minimize.default,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.config.aside.self.minimize.default,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.aside.self.minimize,
                                          "default",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.aside.self.minimize,
                                          "default",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.config.aside.self.minimize,
                                      "default",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Set aside minimized by default "),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("b-tab", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Fixed Footer:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c("span", { staticClass: "switch switch-icon" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.config.footer.fixed,
                                  expression: "config.footer.fixed",
                                },
                              ],
                              attrs: { type: "checkbox", value: "true" },
                              domProps: {
                                checked: Array.isArray(_vm.config.footer.fixed)
                                  ? _vm._i(_vm.config.footer.fixed, "true") > -1
                                  : _vm.config.footer.fixed,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.config.footer.fixed,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.config.footer,
                                          "fixed",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.config.footer,
                                          "fixed",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.config.footer, "fixed", $$c)
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v("Set fixed footer"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                        _vm._v("Width:"),
                      ]),
                      _c("div", { staticClass: "col-lg-9 col-xl-4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.config.footer.width,
                                expression: "config.footer.width",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.config.footer,
                                  "width",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "fluid", selected: "" } },
                              [_vm._v("Fluid")]
                            ),
                            _c("option", { attrs: { value: "fixed" } }, [
                              _vm._v("Fixed"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "form-text text-muted" }, [
                          _vm._v(" Select layout width type. "),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-3" }),
              _c("div", { staticClass: "col-lg-9" }, [
                _vm._m(1),
                _vm._v("   "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-clean font-weight-bold",
                    on: {
                      click: function ($event) {
                        return _vm.reset($event)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-recycle" }), _vm._v(" Reset ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "card card-custom gutter-b" }, [
      _vm._m(2),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "example mb-10" }, [
          _vm._m(3),
          _c("div", { staticClass: "example-code" }, [
            _c("div", { staticClass: "example-code" }, [
              _c("span", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.top",
                    value: "Copy code",
                    expression: "'Copy code'",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "example-copy",
              }),
              _c(
                "div",
                { staticClass: "example-highlight" },
                [
                  _c(
                    "highlight-code",
                    { staticClass: "language-json", attrs: { lang: "json" } },
                    [_vm._v(" " + _vm._s(_vm.config) + " ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert-text" }, [
      _c("p", [
        _vm._v(
          " The layout builder helps to configure the layout with preferred options and preview it in real time. The configured layout options will be saved until you change or reset them. To use the layout builder choose the layout options and click the "
        ),
        _c("code", [_vm._v("Preview")]),
        _vm._v(" button to preview the changes. "),
      ]),
      _c("p", [
        _c(
          "span",
          {
            staticClass:
              "label label-inline label-pill label-info label-rounded mr-2",
          },
          [_vm._v(" INFO: ")]
        ),
        _vm._v(" Some pages from the "),
        _c("code", [_vm._v("html")]),
        _vm._v(" version might not exist in the "),
        _c("code", [_vm._v("vue")]),
        _vm._v(". But the CSS styles from "),
        _c("code", [_vm._v("html")]),
        _vm._v(" version already integrated into the "),
        _c("code", [_vm._v("vue")]),
        _vm._v(". All the components from the "),
        _c("code", [_vm._v("html")]),
        _vm._v(
          " version are static and non-working components. You can copy the component HTML from the "
        ),
        _c("code", [_vm._v("html")]),
        _vm._v(" version, and paste it in the "),
        _c("code", [_vm._v("vue")]),
        _vm._v(
          " component to get the same style. The actual functionality and implementation need to be done by the customer because every application has its own business requirements. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-primary font-weight-bold",
        attrs: { type: "submit" },
      },
      [_c("i", { staticClass: "la la-eye" }), _vm._v(" Preview ")]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-title" }, [
        _c("h3", { staticClass: "card-label" }, [_vm._v("Generated Config")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Use for layout config in "),
      _c("code", [_vm._v("/src/core/config/layout.config.json")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }